import React from 'react'
import LogoSquare from "../../images/logo_square.inline.svg"
import { Link } from 'gatsby'
import {StaticQuery, graphql } from 'gatsby'
import parse from 'html-react-parser'
export default function Footer() {
    const d = new Date();
    let year = d.getFullYear();
    return (
        <StaticQuery
        query={graphql`
          query FooterQuery {
            wp {
                siteSettings {
                    acfOptions {
                        address
                        phone
                        email
                        facebook
                        instagram
                        twitter
                        phoneFormatted
                        googleMapsDirections
                    }
                }
            }
          }
        `}
        render={data => (
            <footer className="site-footer">
                <div className="site-footer__wrapper wrapper">
                <div className="site-footer__top">
                    <div className="site-footer__top--left">
                    <div className="site-footer__address"><a href={data.wp.siteSettings.acfOptions.googleMapsDirections} target="_blank">{parse(data.wp.siteSettings.acfOptions.address)}</a></div>
                    <div className="site-footer__phone"><a href={`tel:${data.wp.siteSettings.acfOptions.phoneFormatted}`}>{data.wp.siteSettings.acfOptions.phone}</a></div>
                    <div className="site-footer__email"><a href={`mailto:${data.wp.siteSettings.acfOptions.email}`}>{data.wp.siteSettings.acfOptions.email}</a></div>
                    </div>
                    <div className="site-footer__top--right">
                    <LogoSquare className="site-footer__logo" />
                    <ul className="site-footer__socials">
                        <li><a href={`${data.wp.siteSettings.acfOptions.facebook}`} className="site-footer__socials-icon site-footer__socials-icon--facebook" target="_blank"></a></li>
                        <li><a href={`${data.wp.siteSettings.acfOptions.instagram}`} className="site-footer__socials-icon site-footer__socials-icon--instagram" target="_blank"></a></li>
                        <li><a href={`${data.wp.siteSettings.acfOptions.twitter}`} className="site-footer__socials-icon site-footer__socials-icon--twitter" target="_blank"></a></li>
                    </ul>
                    </div>
                </div>
                <div className="site-footer__bottom">
                    <div className="site-footer__bottom--left">
                    <Link to="/privacy">Privacy</Link>
                    </div>
                    <div className="site-footer__bottom--right">
                    <div className="credits"><a href="https://designedbyduo.co.uk" target="_blank">Branding & Concept - Duo</a> | <a href="https://builtbymike.co.uk" target="_blank">Website - Built by Mike</a></div>
                    <span className="copyright">&copy; Harrogate Brewery {year}</span>
                    </div>
                </div>
                </div>
            </footer>
        )}
      />
    )
}

