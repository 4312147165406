import React, { useContext } from "react";
import { FiShoppingCart } from "react-icons/fi";
import { ShopifyContext } from "../../context/ShopifyContext";

export default function CartNavItem() {
  const { cartContents, toggleCartVisiblity } = useContext(ShopifyContext);
  // console.log("cartContents", cartContents);
  return (
    <>
      {cartContents !== false && cartContents?.lineItems?.length > 0 ? (
        <div className="cart-icon" onClick={() => toggleCartVisiblity()}>
          <FiShoppingCart />
          <span className="quantity">{cartContents.lineItems.length}</span>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
