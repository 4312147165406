import React from "react";
import { ShopifyContext } from "../../context/ShopifyContext";
import slugify from "../../helpers/slugify";
import CartLineItem from "./CartLineItem";

export default function Cart() {
  return (
    <ShopifyContext.Consumer>
      {({
        handleFetchCheckout,
        cartContents,
        isCartOpen,
        setIsCartOpen,
        handleRemoveFromCart,
      }) => (
        <div className={`cart-wrapper ${isCartOpen ? "active" : ""}`}>
          <div className="cart-wrapper--inner">
            <button
              className="continueShopping"
              onClick={() => setIsCartOpen(false)}
            >
              &larr; Continue shopping
            </button>
            <ul className="cart-contents">
              {cartContents !== undefined
                ? cartContents?.lineItems?.map((item, index) => (
                    <CartLineItem
                      key={`cart_contents_${slugify(
                        item.title + "-" + item.variant.title
                      )}`}
                      item={item}
                      index={index}
                      handleRemoveFromCart={handleRemoveFromCart}
                    />
                  ))
                : ""}
            </ul>
            <div className="cart-footer">
              <div className="totals">
                <div className="total">
                  <span className="label">Total</span>{" "}
                  <span className="totalNumber">
                    {cartContents !== undefined
                      ? `£${parseFloat(
                          cartContents?.totalPrice?.amount
                        ).toFixed(2)}`
                      : ""}
                  </span>
                </div>
                <small>
                  Shipping and taxes will be calculated at checkout.
                </small>
              </div>
              <button
                className="btn btn--black"
                onClick={() => handleFetchCheckout()}
              >
                Proceed to checkout
              </button>
            </div>
          </div>
        </div>
      )}
    </ShopifyContext.Consumer>
  );
}
