import React, { useContext, useEffect, useRef } from "react";
import Logo from "../../images/logo.inline.svg";
import SmallLogo from "../../images/logo_circle_black.inline.svg";
import { StaticQuery, graphql } from "gatsby";
import { FiShoppingCart } from "react-icons/fi";
import { VscChevronDown } from "react-icons/vsc";
import { Helmet } from "react-helmet";
import { ShopifyContext } from "../../context/ShopifyContext";
import ContextLink from "./ContextLink";
import CartNavItem from "./CartNavItem";
import parse from "html-react-parser";

export default function Header() {
  const mobNav = useRef();
  const headerRef = useRef();
  const navToggleRef = useRef();
  const closeNav = () => {
    navToggleRef.current.classList.remove("active");
    mobNav.current.classList.remove("active");
    headerRef.current.classList.remove("active");
  };
  const handleClick = (e) => {
    if (navToggleRef.current.classList.contains("active")) {
      closeNav();
      // closeSubMenus()
    } else {
      navToggleRef.current.classList.add("active");
      mobNav.current.classList.add("active");
      headerRef.current.classList.add("active");
    }
    // console.log("clicked");
    // if (e?.target?.classList.contains("shop-button")) {
    //   navToggleRef.current.classList.remove("active");
    //   mobNav.current.classList.remove("active");
    //   headerRef.current.classList.remove("active");
    // } else {
    //   if (navToggleRef.current.classList.contains("active")) {
    //     navToggleRef.current.classList.remove("active");
    //     mobNav.current.classList.remove("active");
    //     headerRef.current.classList.remove("active");
    //     // closeSubMenus()
    //   } else {
    //     navToggleRef.current.classList.add("active");
    //     mobNav.current.classList.add("active");
    //     headerRef.current.classList.add("active");
    //   }
    // }
  };

  const handleShowSubMenu = (e) => {
    const target = e.target.closest(".has-children").querySelector(".sub-menu");
    if (target.classList.contains("active")) {
      target.classList.remove("active");
    } else {
      target.classList.add("active");
    }
  };

  const updateBannerOffset = () => {
    if (typeof window !== "undefined") {
      const offerbanner = document.querySelector(".offers-banner");
      const header = document.querySelector(".site-head");
      const main = document.querySelector("main");
      const mobileNav = document.querySelector("nav.mobile");
      const footer = document.querySelector(".site-footer");
      const cart = document.querySelector(".cart-wrapper");

      header.style.top = offerbanner.clientHeight + "px";
      main.style.top = offerbanner.clientHeight + "px";
      cart.style.top = offerbanner.clientHeight + "px";
      mobileNav.style.paddingTop = 100 - 20 + offerbanner.clientHeight + "px";
      footer.style.marginTop = offerbanner.clientHeight + "px";
    }
  };

  useEffect(() => {
    updateBannerOffset();

    window.addEventListener("resize", updateBannerOffset);

    return () => {
      window.removeEventListener("resize", updateBannerOffset);
    };
  });

  const { cartContents, toggleCartVisiblity, isCartOpen } = useContext(ShopifyContext);

  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          options: wp {
            siteSettings {
              acfOptions {
                offersBanner
              }
            }
          }
          taproom: wpPage(title: { eq: "Taproom" }) {
            acfTaproomFields {
              upcomingEvents {
                buttonLink {
                  ... on WpPost {
                    id
                    uri
                  }
                }
                buttonText
                dropdownText
              }
              upcomingFood {
                buttonLink {
                  ... on WpPost {
                    id
                    uri
                  }
                }
                buttonText
                dropdownText
              }
              upcomingMusic {
                buttonLink {
                  ... on WpPost {
                    id
                    uri
                  }
                }
                buttonText
                dropdownText
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          {data.options.siteSettings.acfOptions.offersBanner ? <div className="offers-banner">{parse(data.options.siteSettings.acfOptions.offersBanner)}</div> : ""}
          <header className={`site-head ${data.options.siteSettings.acfOptions.offersBanner ? "has-offers-banner" : ""}`} ref={headerRef}>
            <ContextLink onClick={(e) => closeNav(e)} to="/">
              <Logo className="logo" />
              <SmallLogo className="small-logo" />
            </ContextLink>
            <Helmet>
              <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            </Helmet>
            <nav className="desktop">
              <ul className="main-menu">
                <li className="has-children">
                  <span>Beer</span>
                  <VscChevronDown />
                  <ul className="sub-menu">
                    <li>
                      <ContextLink to="/core-range">Core</ContextLink>
                    </li>
                    <li>
                      <ContextLink to="/happy-town">Happy Town</ContextLink>
                    </li>
                    <li>
                      <ContextLink to="/collabs">Collabs</ContextLink>
                    </li>
                    <li>
                      <ContextLink to="/hbco-gin">HBCo Gin</ContextLink>
                    </li>
                  </ul>
                </li>
                <li className="has-children">
                  <span>About</span>
                  <VscChevronDown />
                  <ul className="sub-menu">
                    <li>
                      <ContextLink to="/about">About Us</ContextLink>
                    </li>
                    <li>
                      <ContextLink to="/awards/">Awards</ContextLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <ContextLink to="/latest-news/">Latest News</ContextLink>
                </li>
                <li className="has-children">
                  <ContextLink to="/taproom/">Taproom</ContextLink>
                  <VscChevronDown />
                  <ul className="sub-menu">
                    <li>
                      <ContextLink to="/taproom">Info</ContextLink>
                    </li>
                    <li>
                      <ContextLink to="/food">Food</ContextLink>
                    </li>
                    <li>
                      <ContextLink to="/events">Events</ContextLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <ContextLink to="/trade/">Trade</ContextLink>
                </li>
                <li>
                  <ContextLink to="/faqs/">FAQ's</ContextLink>
                </li>

                <li>
                  <ContextLink to="/contact/">Contact</ContextLink>
                </li>
              </ul>
            </nav>
            <div className="right">
              <div className="cart">
                <ContextLink
                  className="btn btn--black shop-button"
                  onClick={(e) => {
                    if (isCartOpen === true) {
                      toggleCartVisiblity();
                    }
                    closeNav(e);
                  }}
                  to="/shop/">
                  Shop
                </ContextLink>
                <CartNavItem />
                <div ref={navToggleRef} className="site-head__toggle" role="button" onClick={(e) => handleClick(e)}>
                  <div className="site-head__toggle__line site-head__toggle__line--top"></div>
                  <div className="site-head__toggle__line site-head__toggle__line--middle"></div>
                  <div className="site-head__toggle__line site-head__toggle__line--bottom"></div>
                </div>
              </div>
            </div>
          </header>
          <nav className="mobile" ref={mobNav}>
            <ul className="main-menu">
              <li className="has-children" onClick={(e) => handleShowSubMenu(e)}>
                <span>Beer</span>
                <VscChevronDown />
                <ul className="sub-menu">
                  <li>
                    <ContextLink onClick={(e) => handleClick(e)} to="/core-range">
                      Core
                    </ContextLink>
                  </li>
                  <li>
                    <ContextLink onClick={(e) => handleClick(e)} to="/happy-town">
                      Happy Town
                    </ContextLink>
                  </li>
                  <li>
                    <ContextLink onClick={(e) => handleClick(e)} to="/collabs">
                      Collabs
                    </ContextLink>
                  </li>
                  <li>
                    <ContextLink onClick={(e) => handleClick(e)} to="/hbco-gin">
                      HBCo Gin
                    </ContextLink>
                  </li>
                </ul>
              </li>
              <li className="has-children" onClick={(e) => handleShowSubMenu(e)}>
                <span>About</span>
                <VscChevronDown />
                <ul className="sub-menu">
                  <li>
                    <ContextLink onClick={(e) => handleClick(e)} to="/about">
                      About Us
                    </ContextLink>
                  </li>
                  <li>
                    <ContextLink onClick={(e) => handleClick(e)} to="/awards/">
                      Awards
                    </ContextLink>
                  </li>
                </ul>
              </li>
              <li>
                <ContextLink onClick={(e) => handleClick(e)} to="/latest-news/">
                  Latest News
                </ContextLink>
              </li>
              <li className="has-children" onClick={(e) => handleShowSubMenu(e)}>
                <a>Taproom</a>
                <VscChevronDown />
                <ul className="sub-menu">
                  <li>
                    <ContextLink onClick={(e) => handleClick(e)} to="/taproom/">
                      Our Taproom
                    </ContextLink>
                  </li>
                  {data?.taproom?.acfTaproomFields?.upcomingEvents?.buttonLink ? (
                    <li>
                      <ContextLink onClick={(e) => handleClick(e)} to={data.taproom.acfTaproomFields.upcomingEvents.buttonLink.uri}>
                        {data.taproom.acfTaproomFields.upcomingEvents.dropdownText}
                      </ContextLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {data?.taproom?.acfTaproomFields?.upcomingFood?.buttonLink ? (
                    <li>
                      <ContextLink onClick={(e) => handleClick(e)} to={data.taproom.acfTaproomFields.upcomingFood.buttonLink.uri}>
                        {data.taproom.acfTaproomFields.upcomingFood.dropdownText}
                      </ContextLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {data?.taproom?.acfTaproomFields?.upcomingMusic?.buttonLink ? (
                    <li>
                      <ContextLink onClick={(e) => handleClick(e)} to={data.taproom.acfTaproomFields.upcomingMusic.buttonLink.uri}>
                        {data.taproom.acfTaproomFields.upcomingMusic.dropdownText}
                      </ContextLink>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
              <li>
                <ContextLink onClick={(e) => handleClick(e)} to="/trade/">
                  Trade
                </ContextLink>
              </li>
              <li>
                <ContextLink onClick={(e) => handleClick(e)} to="/faqs/">
                  FAQ's
                </ContextLink>
              </li>

              <li>
                <ContextLink onClick={(e) => handleClick(e)} to="/contact/">
                  Contact
                </ContextLink>
              </li>
            </ul>
          </nav>
        </>
      )}
    />
  );
}
