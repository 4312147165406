import React, { useState } from "react";
import { useEffect } from "react";
import slugify from "../../helpers/slugify";

export default function ShopifyProductImage({ productTitle, client }) {
  const [shopifyImage, setShopifyImage] = useState();

  useEffect(() => {
    if (shopifyImage === undefined) {
      client.product.fetchByHandle(slugify(productTitle)).then((product) => {
        // Do something with the product
        setShopifyImage(product.images[0].src);
      });
    }
  });

  return <img className="cart_item_image" src={shopifyImage} />;
}
