import React, { useEffect } from "react";
import Header from "../components/global/Header";
import Footer from "../components/global/Footer";
import AgeGate from "../components/global/AgeGate";
import Cart from "../components/global/Cart";

export default function Layout({ children }) {
  const calcViewportHeight = () => {
    if (typeof window !== undefined) {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  };
  useEffect(() => {
    if (typeof window !== undefined) {
      calcViewportHeight();
      window.addEventListener("resize", () => calcViewportHeight());
    }
  }, []);

  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
      <AgeGate />
      <Cart />
    </>
  );
}
