exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-templates-events-archive-js": () => import("./../../../src/templates/events-archive.js" /* webpackChunkName: "component---src-templates-events-archive-js" */),
  "component---src-templates-flexible-pages-js": () => import("./../../../src/templates/flexible-pages.js" /* webpackChunkName: "component---src-templates-flexible-pages-js" */),
  "component---src-templates-flexible-posts-js": () => import("./../../../src/templates/flexible-posts.js" /* webpackChunkName: "component---src-templates-flexible-posts-js" */),
  "component---src-templates-food-archive-js": () => import("./../../../src/templates/food-archive.js" /* webpackChunkName: "component---src-templates-food-archive-js" */),
  "component---src-templates-latest-news-archive-js": () => import("./../../../src/templates/latest-news-archive.js" /* webpackChunkName: "component---src-templates-latest-news-archive-js" */),
  "component---src-templates-product-archive-js": () => import("./../../../src/templates/product-archive.js" /* webpackChunkName: "component---src-templates-product-archive-js" */),
  "component---src-templates-product-single-js": () => import("./../../../src/templates/product-single.js" /* webpackChunkName: "component---src-templates-product-single-js" */)
}

