import React, { createContext, useState } from "react";
import Client from "shopify-buy";
import { navigate } from "gatsby";
import { useEffect } from "react";

const defaultState = {
  client: "",
  cartContents: false,
  isCartOpen: false,
  setIsCartOpen: () => {},
  handleAddToCart: () => {},
  handleRemoveFromCart: () => {},
  handleFetchCheckout: () => {},
  toggleCartVisiblity: () => {},
  updateLineItem: () => {},
};

export const ShopifyContext = createContext(defaultState);

export const ShopifyContextProvider = ({ children }) => {
  const client = Client.buildClient({
    domain: process.env.GATSBY_MYSHOPIFY_URL,
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  });

  const [checkoutId, setCheckoutId] = useState(
    window.localStorage.getItem("shopify_checkout_id_hgate")
  );
  const [checkoutIdTimestamp, setCheckoutIdTimestamp] = useState(
    window.localStorage.getItem("shopify_checkout_id_hgate_timestamp")
  );

  const [cartContents, setCartContents] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const createCheckout = () => {
    client.checkout.create().then((checkout) => {
      // Do something with the checkout
      setCheckoutId(checkout.id);
      localStorage.setItem("shopify_checkout_id_hgate", checkout.id);
      setCheckoutIdTimestamp(Date.now());
      localStorage.setItem("shopify_checkout_id_hgate_timestamp", Date.now());
    });
  };

  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    // console.log("is browser");
    // If no Checkout has been made previously generate new Checkout and store CheckoutID in localStorage
    if (checkoutIdTimestamp === null && checkoutId === null) {
      // console.log("nothing exists");
      // localStorage.setItem("shopify_checkout_id_hgate", "test");
      // localStorage.setItem("shopify_checkout_id_hgate_timestamp", Date.now());
      // console.log("checkout created with NEW id");
      createCheckout();
    }

    if (parseInt(checkoutIdTimestamp) + 1000 * 60 * 30 < Date.now()) {
      // console.log({
      //   message: "checkout timestamp exists but has expired",
      //   now: Date.now(),
      //   timestamp: parseInt(checkoutIdTimestamp) + 1000 * 10,
      //   timestampPLusMinuteLessThanNow:
      //     Date.now() > parseInt(checkoutIdTimestamp) + 1000 * 10,
      // });
      createCheckout();
    } else {
      // console.log("checkout timestamp exists");
      if (checkoutId === null) {
        // console.log("checkout created with existing id");
        createCheckout();
      }
    }
  }
  const updateCartContents = () => {
    if (isBrowser && checkoutId !== null) {
      client.checkout.fetch(checkoutId).then((checkout) => {
        // Do something with the checkout
        if (checkout?.lineItems.length > 0) {
          setCartContents(checkout);
        } else {
          setCartContents(undefined);
        }
      });
    }
  };

  const updateLineItems = (id, quantity) => {
    if (isBrowser && checkoutId !== null) {
      const lineItemsToUpdate = [{ id, quantity }];

      client.checkout
        .updateLineItems(checkoutId, lineItemsToUpdate)
        .then((checkout) => {
          updateCartContents();
        });
    }
  };

  const handleAddToCart = (variantId, quantity, ImageSrc) => {
    const lineItemsToAdd = [
      {
        variantId,
        quantity,
        // customAttributes: [{ key: "ImageSrc", value: ImageSrc }],
      },
    ];
    client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then((checkout) => {
        updateCartContents();
      });
  };

  const handleRemoveFromCart = (variantId) => {
    const lineItemIdsToRemove = [variantId];
    client.checkout
      .removeLineItems(checkoutId, lineItemIdsToRemove)
      .then((checkout) => {
        updateCartContents();
      });
  };

  const handleFetchCheckout = () => {
    client.checkout.fetch(checkoutId).then((checkout) => {
      navigate(checkout.webUrl);
    });
  };

  const toggleCartVisiblity = () => {
    setIsCartOpen(!isCartOpen);
  };

  // When cart items are removed and there in no longer any items in the cart, hide the cart window automatically.
  useEffect(() => {
    if (cartContents === false) {
      setIsCartOpen(false);
    }
  }, [cartContents]);

  if (cartContents === false) {
    updateCartContents();
  }

  return (
    <ShopifyContext.Provider
      value={{
        client,
        cartContents,
        isCartOpen,
        setIsCartOpen,
        handleAddToCart,
        handleRemoveFromCart,
        handleFetchCheckout,
        toggleCartVisiblity,
        updateLineItems,
      }}
    >
      {children}
    </ShopifyContext.Provider>
  );
};
