import React, { useState, useContext } from "react";
import ShopifyProductImage from "./ShopifyProductImage";
import { ShopifyContext } from "../../context/ShopifyContext";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { useEffect } from "react";
import slugify from "../../helpers/slugify";
import { FreeMode } from "swiper";

export default function CartLineItem({
  item,
  cartContents,
  handleRemoveFromCart,
}) {
  // console.log(item);

  useEffect(() => {
    if (quantity !== item.quantity) {
      setTimeout(() => {
        setQuantity(item.quantity);
      }, 200);
    }
  }, [item]);

  const [quantity, setQuantity] = useState(item.quantity);
  const [itemTotal, setItemTotal] = useState();
  const { updateLineItems, client } = useContext(ShopifyContext);

  const handleQtyUpdate = (e) => {
    if (e.target.classList.contains("minus")) {
      setQuantity(parseInt(quantity) - 1);
    }
    if (e.target.classList.contains("plus")) {
      setQuantity(parseInt(quantity) + 1);
    }
  };

  const calculateItemTotal = () => {
    const qty = parseInt(quantity);
    const unitPrice = parseFloat(item.variant.price.amount);
    const itemTotal = `£${(qty * unitPrice).toFixed(2)}`;

    if (isNaN((qty * unitPrice).toFixed(2))) {
      setItemTotal("");
    } else {
      setItemTotal(itemTotal);
    }
  };

  useEffect(() => {
    calculateItemTotal();
    // console.log(item.id);
    updateLineItems(item.id, parseInt(quantity));
    // console.log(item.variant.title, item.variant.price.amount);
  }, [quantity, cartContents]);

  return (
    <li
      id={`cart_contents_${slugify(item.title + "-" + item.variant.title)}`}
      className="cart_line_item"
    >
      <ShopifyProductImage productTitle={item.title} client={client} />

      <div className="cart-item-wrapper">
        <div className="cart-item--header">
          <div className="cart-item--title">
            <div className="cart-item--title--main">{item.title}</div>
            <div className="cart-item--title--sub">{item.variant.title}</div>
            <div className="cart-item--title--sub">
              £{parseFloat(item.variant.price.amount).toFixed(2)}
            </div>
          </div>
          <button
            className="removeItem"
            onClick={() => handleRemoveFromCart(item.id)}
          >
            <BsTrash onClick={() => handleRemoveFromCart(item.id)} />
          </button>
        </div>
        <div className="cart-item--footer">
          <div className="cart-item--qty">
            <small>Quantity</small>
            <div className="number-wrapper">
              <AiOutlineMinus
                className="minus icon"
                onClick={(e) => handleQtyUpdate(e)}
              />
              <input
                type="number"
                min={1}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => setQuantity(e.target.value)}
                value={quantity}
              />
              <AiOutlinePlus
                className="plus icon"
                onClick={(e) => handleQtyUpdate(e)}
              />
            </div>
          </div>
          <div className="cart-item-total">
            {itemTotal}
            {/* {item.variant.price.amount} */}
          </div>
        </div>
      </div>
    </li>
  );
}
