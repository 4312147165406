import "normalize.css";
import "./src/styles/reset.css";
// import "./src/fonts/typography.css"
import "./src/sass/style.scss";
import React from "react";

import { ShopifyContextProvider } from "./src/context/ShopifyContext";

export const wrapRootElement = ({ element }) => (
  <ShopifyContextProvider>{element}</ShopifyContextProvider>
);
