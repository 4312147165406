import React, { useEffect, useState } from "react";
import { VscAccount } from "react-icons/vsc";
import Logo from "../../images/logo_circle_white.inline.svg";
export default function AgeGate() {
  const setAgeConsent = () => {
    sessionStorage.setItem("isEighteen", true);
    setIsEighteen(true);
  };

  const [isEighteen, setIsEighteen] = useState();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const isEighteenCheck = sessionStorage.getItem("isEighteen");

      if (isEighteenCheck === null) {
        setIsEighteen(false);
      }
    }
  }, []);

  return (
    <div className={`age-gate ${isEighteen === false ? "show" : ""}`}>
      <Logo />
      <div className="text">
        You must be over 18 to view our website. By entering the site you are
        confirming you’re over the age of 18.
      </div>
      <button className="btn btn--white" onClick={() => setAgeConsent()}>
        Enter site
      </button>
    </div>
  );
}
