import React from "react";
import { ShopifyContext } from "../../context/ShopifyContext";
import { Link } from "gatsby";
import { useContext } from "react";

export default function ContextLink({ to, onClick, children, className }) {
  const { setIsCartOpen, isCartOpen } = useContext(ShopifyContext);

  return (
    <Link
      to={to}
      className={className}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {children}
    </Link>
  );
}
